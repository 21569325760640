import { Clock, Mail, MapPin, Phone } from 'lucide-react'
import Img from "../assets/Img.png"
import Toyota from "../assets/Logo.png"
import Ford from "../assets/Logo(1).png"
import Mercedes from "../assets/Logo(2).png"
import Jeep from "../assets/Logo(3).png"
import BMW from "../assets/Logo(4).png"
import Audi from "../assets/Logo(5).png"
import { useNavigate } from 'react-router-dom'
export default function ContactForm() {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-8">Contact Us</h1>
      
      <div className="grid lg:grid-cols-3 gap-8">
        {/* Booking Form */}
        <div
          className="lg:col-span-1 p-4 sm:p-6 rounded-lg shadow"
          style={{ backgroundColor: '#78aae5' }}
        >
          <h2 className="text-2xl sm:text-3xl flex justify-center font-semibold text-white mb-2">Book your car</h2>
          <form className="space-y-4">
            <input 
              type="text" 
              placeholder="Full Name" 
              className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input 
              type="email" 
              placeholder="Email address" 
              className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select 
              className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Type of Vehicle</option>
              <option value="sedan">Sedan</option>
              <option value="suv">SUV</option>
              <option value="luxury">Luxury</option>
            </select>
            <input 
              type="date" 
              placeholder="Pickup date" 
              className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select 
              className="w-full p-2 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Pickup time</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </select>
            <button 
               onClick={() => navigate("/ComingSoon")}
              type="submit" 
              className="w-full bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Book now
            </button>
          </form>
        </div>

        {/* Map Placeholder */}
        <div className="lg:col-span-2 rounded-3xl min-h-[200px] flex justify-center items-center">
          <img src={Img} alt="Map" className="w-full h-auto max-h-[400px] object-cover rounded-3xl" />
        </div>
      </div>

      {/* Contact Information */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8">
        <div className="flex items-center gap-3">
          <div className="bg-blue-100 p-3 rounded-full">
            <Phone className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="font-medium">Phone</p>
            <p className="text-sm text-gray-600">+1(979)997-9556</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="bg-blue-100 p-3 rounded-full">
            <Mail className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="font-medium">Email</p>
            <p className="text-sm text-gray-600">CatoDrive@outlook.com</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="bg-blue-100 p-3 rounded-full">
            <MapPin className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="font-medium">Location</p>
            <p className="text-sm text-gray-600">2534 Central Drive, APt#126 Bedford TX 76021 United States</p>
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div className="bg-blue-100 p-3 rounded-full">
            <Clock className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="font-medium">Working Hours</p>
            <p className="text-sm text-gray-600">Mon-Sun: 9am - 9pm</p>
          </div>
        </div>
      </div>

      {/* Blog Posts Section */}
      <div className="mt-16 sm:mt-28">
        <h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-8 sm:mb-16">Latest blog posts & news</h2>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8 sm:mt-20 mb-8 sm:mb-16">
          <div className="rounded-lg overflow-hidden shadow">
            <img 
              src={Img}
              alt="Right car" 
              className="w-full h-48 sm:h-60 object-cover"
            />
            <div className="p-4">
              <h3 className="font-semibold text-xl mb-2">How To Choose The Right Car</h3>
              <p className="text-sm text-gray-600">Posted: 7 Dec 2023</p>
            </div>
          </div>

          <div className="rounded-lg overflow-hidden shadow">
            <img 
              src={Img}
              alt="Right car" 
              className="w-full h-48 sm:h-60 object-cover"
            />
            <div className="p-4">
              <h3 className="font-semibold text-xl mb-2">Which plan is right for me?</h3>
              <p className="text-sm text-gray-600">Posted: 5 Dec 2023</p>
            </div>
          </div>

          <div className="rounded-lg overflow-hidden shadow sm:col-span-2 md:col-span-1">
            <img 
              src={Img}
              alt="Right car" 
              className="w-full h-48 sm:h-60 object-cover"
            />
            <div className="p-4">
              <h3 className="font-semibold text-xl mb-2">Enjoy Speed, Choose & Total Control</h3>
              <p className="text-sm text-gray-600">Posted: 3 Dec 2023</p>
            </div>
          </div>
        </div>

        {/* Car Manufacturer Logos */}
        <div className="flex flex-wrap justify-center items-center gap-8 sm:gap-12 md:gap-16 lg:gap-20 pt-20">
          <img src={Toyota} alt="Toyota" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
          <img src={Ford} alt="Ford" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
          <img src={Mercedes} alt="Mercedes" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
          <img src={Jeep} alt="Jeep" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
          <img src={BMW} alt="BMW" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
          <img src={Audi} alt="Audi" className="h-6 sm:h-8 md:h-10 opacity-50 hover:opacity-100 transition-opacity" />
        </div>
      </div>
    </div>
  )
}

